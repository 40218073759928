<template>
  <span>
    <h1 class="page-header">{{'Change Email' | translate}}</h1>
    <div class="row">
      <!-- begin col-12 -->
      <div class="col-12">
        <!-- begin panel -->
        <b-alert variant="danger" show>
          {{ "WARNING! Following the modification of the email, an automatic logout will be performed. Furthermore, an email will be sent to the new email address to verify its correctness. It will not be possible to login to the system until the verification has been carried out." | translate }}
        </b-alert>
        <div class="card border-0">
          <div class="card-body">
            <form>
              <form-group-thux
                :validator="$v.form.old_password"
                :label="'Old password' | translate"
                class="col mb-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="password"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.old_password" />
                </template>
              </form-group-thux>
              <form-group-thux
                :validator="$v.form.new_email"
                :label="'New email' | translate"
                class="col mb-2 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="email"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_email" />
                </template>
              </form-group-thux>
              <form-group-thux
                :validator="$v.form.new_confirm_email"
                :label="'Confirm new email' | translate"
                class="col mb-0 mt-2">
                <template slot-scope="{ attrs, listeners }">
                  <b-form-input
                    type="email"
                    v-bind="attrs"
                    v-on="listeners"
                    v-model="form.new_confirm_email" />
                </template>
              </form-group-thux>
            </form>
          <!-- end hljs-wrapper -->
          </div>
          <div class="card-footer f-w-600 d-flex justify-content-end">
            <button
              class="btn btn-default mr-3"
              @click.prevent="cancel()">
              {{ 'Cancel' | translate }}
            </button>
            <button
              class="btn btn-success"
              @click.prevent="onSubmit()"
              :disabled="$v.form.$invalid">
              {{ 'Change Email' | translate }}
            </button>
          </div>
        </div>
        <!-- end panel -->
      </div>
      <!-- end col-6 -->
    </div>
  </span>
</template>

<script>
import { required, email, sameAs } from 'vuelidate/lib/validators'
import PageOptions from '@/config/PageOptions.vue'
import { mapActions } from 'vuex'
import { TYPES as AUTH_TYPES } from './store'

export default {
  name: 'ChangeEmail',
  data () {
    return {
      form: {}
    }
  },
  created () {
    PageOptions.pageContentFullWidth = false
  },
  validations: {
    form: {
      old_password: {
        required
      },
      new_email: {
        required,
        email
      },
      new_confirm_email: {
        required,
        email,
        sameAsPassword: sameAs('new_email')
      }
    }
  },
  methods: {
    ...mapActions({
      changeEmail: AUTH_TYPES.AUTH.changeEmail,
      logout: AUTH_TYPES.AUTH.logout
    }),
    cancel () {
      this.$router.replace({ name: 'dashboard' })
    },
    onSubmit () {
      if (!this.$v.$invalid) {
        this.changeEmail(this.form).then(
          () => {
            setTimeout(() => {
              this.logout()
            }, 1000)
          }
        )
      }
    }
  }
}
</script>
